.side-nav{
    display: flex;
    flex-direction: column;
    z-index: 1;
    
    .side-link-item{
        text-decoration: none;
        cursor: pointer;
        display: flex;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin: 8px 0;
        align-items: center;

        .side-link-items-subcontainer{
            width: 100%;
            display: flex;
            align-items: center;
            
            .side-link-item-icon{
                width: 30px;
                padding: 9px;
            }
        }

        & :hover{
           background-color: rgba(48,124,196,0.2);
       }

       &:first-child{
        margin-top: 17px;
       }
    }

    .side-link-active{
      background-color: #307cc4;  
    }
}

.side-nav-close{
    height: calc(100vh - 57px);
    background-color: #292929;
    width: 48px;
    transition: 0.5s;

}

.side-nav-open{
    height: calc(100vh - 57px);
    background-color: #292929;
    width: 248px;
    transition: 0.5s;

}

.side-bar-fixed{
    position: fixed;
    left: 0;
    top: 57px;
}