.modal-back-drop{
    background-color: rgba(0,0,0,0.75) !important;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    .modal-view{
        background-color: #fff;
        width: 50%;
        height: 50vh;
    }
}

.ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.75);

    .ReactModal__Content{
        inset: 56px 47px !important;
        width: 62%;
        height: 84%;
        margin: auto;
        text-align: center;
    }
}
