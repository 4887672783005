.order-details-heading-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .just-order{
        font-size: 32px;
    }

    .order-number{
        color: #307cc4;
        // margin: 0;
        font-size: 27px;
    }

    .bto-cta{
        display: flex;
        align-items: center;
        color: #307cc4;
        padding: 0 3px;
        border-radius: 4px;
        cursor: pointer;

        .bto-arrow{
            font-size: 28px;
            margin-right: 6px;
        }

        .bto-text{
            font-weight: 600;
        }

        &:hover{
            background-color: #e8eaeb;
            transition: 0.3s;
        }
    }
}