.Toastify__toast-container{
    width: fit-content !important;

    .toast-text-container{
        display: flex;
        align-items: center;
    
        .toast-icon{
            margin-right: 8px;
        }
    }
}