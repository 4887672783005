.order-summary-container{
    margin-top: 12px;

    .os-cd-wrapper{
        display: flex;
        margin-bottom: 8px;

        .os-div{
            background-color: #f7f7f7;
            padding: 16px;
            width: 47%;
            margin-right: 8px;
        }
    }
    
    .os-data-container{
        display: flex;
        justify-content: space-between;
    }

    .os-heading{
        margin: 0 0 10px 0;
        font-size: 18px;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 6px;
    }
    .cd-s-wrapper{
        display: flex;
    }

    .label-data-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;

        .os-labels{
            font-weight: 600;
            color: #6d6d6d;
        }
        
        .os-labels-data{
            font-weight: 600;
            padding-left: 8px;
            font-size: 14px;
        }
    }

    .customer-div{
        background-color: #f7f7f7;
        padding: 16px;
        flex-grow: 1;
    }

    .margin-btn{
        margin-bottom: 8px;
    }
}
