.item-container{
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;

    .item-card{
        display: flex;
        flex-direction: column;
        padding: 8px;
        background-color: #f7f7f7;
        margin-right: 8px;
        margin-bottom: 8px;
        max-width: 180px;

        .card-img{
            width: 180px;
            height: 154px;
        }

        .card-label{
            font-weight: 600;
            margin: 6px 0;
            word-break: break-word;
        }

        .card-qty-label{
            text-align: right;
        }
    }
}