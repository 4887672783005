.login-box-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #307cc4;

    .login-box{
        width: 400px;
        height: 240px;
        padding: 50px;
        background-color: #fff;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    
        .login-heading-container{
            border-left: 3px solid #307cc4;
            padding: 16px 8px 16px 4px;
            margin-bottom: 16px;
        }
    
        .login-label-input{
            margin-bottom: 16px;
    
            .login-label{
                color: #828282;
            }
        
            .login-input{
                padding: 14px 8px;
                width: 95%;
                border: 1px solid #eee;
                outline: none;
            }
        
            .forgot-password{
                font-size: 11px;
                text-align: right;
                width: 100%;
                float: right;
                color: #307cc4;
            }
        }
    
        .login-submit{
            width: 100%;
            margin-top: 8px;
            padding: 14px;
            border: none;
            border-radius: 4px;
            background-color: #307cc4;
            color: #fff;
            cursor: pointer;
            outline: none;
        }
    
    }
}