.content-container{
    margin: 74px 16px 16px 60px;
    width: -webkit-fill-available;
}

@-moz-document url-prefix() {
    .content-container{
        margin: 74px 16px 16px 60px;
        width: 100%;
    }
  }