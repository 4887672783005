.order-hub-table-filter-container{
    padding: 16px;
    background-color: #f7f7f7;
    margin-top: 12px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .pagination-filter-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 12px;
        width: 100%;

        .funnel-filter-container{
            // padding: 8px;
            display: flex;
            border: 1px solid #307cc4;
            border-radius: 6px;
            margin-left: 8px;
            cursor: pointer;
            position: relative;

            &:hover{
                background-color: #e8eaeb;
                transition: 0.3s;
            }

            &:first-child{
                margin-left: 0;
                margin-right: 8px;
            }

            .sub-container{
                padding: 6px;
                line-height: 0;
            }

            .column-filters-container{
                padding: 12px;
                position: absolute;
                right: 0;
                background-color: #fff;
                top: 32px;
                width: max-content;
                box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.08);
                
                .column-sub-filters-container{
                    column-count: 2;

                }
            }
        }
        
    }

    .order-table-container{
        overflow-x: auto;

        .order-table{
            border-collapse: collapse;
            width: 100%;

            .cell-padding-wrap{
                padding: 8px;
                white-space: nowrap;
            }

            .header-cell-bg{
                background-color: #307cc4;
                color: #fff;
                cursor: grab;
            }

            .cell-text-align-left{
                text-align: left;
            }
        }

        .row-highligh{

            &:hover{
                background-color: #c3cfdb;
            }
        }
        
        .hidden-col{
            display: none;
        }
    }
}

.pagination {
    display: flex;
    list-style: none;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #307cc4;
    border-radius: 6px;
    margin: 0;
}

.pagination a {
    padding: 2px 8px;
    border-radius: 3px;
    color: #454545;
    font-weight: 600;
    outline: none;
}

.pagination__link {
    font-weight: 600;
}

.pagination__link--active a {
    color: #fff;
    background: #307cc4;
}

.pagination__link--disabled a {
    color: rgb(198, 197, 202);
    // border: 1px solid rgb(198, 197, 202);
}

.sync-icon{
    width: 17px;
}

.sync-loading {
    width: 17px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.no-data-text{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #307cc4;
    width: 100%;
}

