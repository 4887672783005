.select-filter-by{
    width: 100%;
    margin-right: 8px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.filter-by-label{
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}