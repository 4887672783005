.nav-bar{
    width: 100%;
    background-color: #f7f7f7;
    display: flex;
    border-bottom: 3px solid #307cc4;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    position: fixed;
    top: 0;
    z-index: 1;

    .nav-logo{
        margin-left: 16px;
        font-size: 18px;
        font-weight: 600;
    }

    .nav-right-items{
        display: flex;
        margin-right: 16px;
        height: 100%;

        .top-nav-user-name-hover{
            cursor: pointer;
            position: relative;

            .top-nav-user-name{
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 8px;
            }

            .top-nav-drop-icon{
                margin-left: 8px;
            }
            
            & :hover{
                background-color: #d6d6d6;
            }

            .top-nav-drop-down-collapsed{
                position: absolute;
                width: 200px;
                height: 0;
                background-color: #f7f7f7;
                transition: height 0.3s;
                z-index: 1;
                top: 57px;
                right: 0px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);

                .top-drop-items-container{
                    display: none;
                    padding: 12px;
    
                    .drop-item-img{
                        width: 22px;
                        margin-right: 8px;
                    }
                }
            }

            .top-nav-drop-down-open{
                position: absolute;
                width: 200px;
                height: 92px;
                background-color: #f7f7f7;
                transition: height 0.3s;
                z-index: 1;
                top: 57px;
                right: 0px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);

                .top-drop-items-container{
                    display: flex;
                    padding: 12px;
    
                    .drop-item-img{
                        width: 22px;
                        margin-right: 8px;
                    }
                }
            }
        }

        .notification-badge{
            display: flex;
            align-items: center;
            margin-right: 16px;
        }
    }
}