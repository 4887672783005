.tabs-container{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 9px;
    margin-top: 12px;

    .tab-style{
        padding: 8px 12px;
        color: lightgrey;
        font-size: 18px;
        cursor: pointer;
    }

    .active-tab{
        color: #307cc4;
        font-weight: 600;
        border-bottom: 2px solid #307cc4;
    }
}