.order-filters-container{
    background-color: #f7f7f7;
    font-size: 13px;
    padding: 16px;
    transition: 0.5s;
}

.order-filters-container-hidden{
    background-color: #f7f7f7;
    font-size: 13px;
    padding: 16px;
    height: 40px;
    transition: 0.5s;
    text-align: center;
}

.visible-filter-row{
    display: flex;
    justify-content: space-between;

    .quick-summary-input-box{
        width: 50%;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 8px;

        .search-icon{
            position: absolute;
            left: 8px;
        }

        .quick-summary-input{
            width: 100%;
            padding: 10px 8px 10px 32px;
            border: 1px solid lightgrey;
            border-radius: 3px;
            outline: none;
        }
    }

    .quick-summary-select-search-by{
        min-width: 30%;
        margin-right: 8px;
        text-align: left;
    }

    .quick-summary-filter-btn-group{
        display: flex;
        margin-right: 8px;

        .quick-summary-filter-search-btn{
            padding: 10px;
            border: none;
            background-color: #307cc4;
            color: #fff;
            border-radius: 6px;
            outline: none;
            width: 138px;
            margin-right: 8px;
            font-weight: 600;
            cursor: pointer;
            transition: 0.3s;

            &:hover{
                background-color: #6097ca;
                transition: 0.3s;
            }
        }

        .quick-summary-filter-clear-btn{
            padding: 10px;
            border: 1px solid #307cc4;
            color: #307cc4;
            background-color: transparent;
            border-radius: 6px;
            outline: none;
            width: 138px;
            font-weight: 600;
            cursor: pointer;
            transition: 0.3s;

            &:hover{
                background-color: #e8eaeb;
                transition: 0.3s;
            }
        }
    }

    .chevron-btn{
        padding: 8px;
        display: flex;
        border: 1px solid #307cc4;
        border-radius: 6px;
        cursor: pointer;
        transition: 0.3s;

        img{
            width: 16px;
            transition: 0.2s;
        }

        &:hover{
            background-color: #e8eaeb;
            transition: 0.3s;
        }
    }
}

.hidden-filter-row{
    display: flex;
    margin-top: 8px;
    transition: 0.5s;
}

.hidden-filter-row-hide{
    display: none;
    transition: 0.5s;
}

.common-hidden-input-box{
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin-right: 8px;

    .common-hidden-input-label-box{
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 8px;
        
        &:last-child{
            margin-right: 0px;
        }

        label{
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 4px;
        }

        .common-hidden-input{
            padding: 10px 8px 10px 8px;
            border: 1px solid lightgrey;
            border-radius: 3px;
            outline: none;
        }
    }
}

.date-range-box{
    width: 40%;

    label{
        font-weight: 600;
        font-size: 14px;
    }
    
    .date-range-input-box{
        display: flex;
        margin-top: 4px;

        .react-datepicker-wrapper{
            width: 100%;
            margin-right: 8px;

            .date-range-input{
                padding: 10px;
                border: 1px solid lightgrey;
                border-radius: 3px;
                outline: none;
                width: -webkit-fill-available;
                width: -moz-available;
            }

            &:last-child{
                margin-right: 0px;
            }
        }

    }
}

.order-status-container{
    padding-right: 16px;
    border-right: 1px solid lightgrey;

    .heading-label{
        font-weight: 600;
        font-size: 14px;
    }

    // .checkbox-group-box{
    //     display: flex;
    //     flex-direction: column;              //NO NEED RN
    //     margin-right: 8px;

    // }

    .checkbox-item{
        display: flex;
        align-items: center;
        width: max-content;
        margin-bottom: 6px;

        .other-boolean-containers{
            display: flex;
            margin-right: 3px;

            .boolean-filters-btn{
                font-size: 11px;
                padding: 3px;
                height: 18px;
                display: flex;
                align-items: center;
                border: 1px solid gray;
                outline: none;
                cursor: pointer;

                &:first-child{
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border-right: 0px;
                }

                &:last-child{
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }

    .price-input-box{
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        &:last-child{
            margin-bottom: 0px;
        }

        .price-input{
            width: 100px;
            border: 1px solid lightgrey;
            border-radius: 3px;
            outline: none;
            margin-left: 8px;
            
        }
    }

    &:last-child{
        padding-right: 0px;
        border-right: 0px;
    }
}
