.simple-table{
    border-collapse: collapse;
    width: 100%;
    font-size: 13px;

    .cell-padding-wrap{
        padding: 8px;
        white-space: nowrap;
    }

    .header-cell-bg{
        background-color: #307cc4;
        color: #fff;
    }

    .cell-text-align-center{
        text-align: center;
    }
}