.oder-hub-tags-container{
    padding: 16px;
    background-color: #f7f7f7;
    margin-top: 12px;
    display: flex;

    .order-hub-tag{
        padding: 4px 6px;
        font-size: 12px;
        font-weight: 600;
        background-color: #307cc4;
        border-radius: 3px;
        color: #fff;
        margin-right: 8px;

        &:last-child{
            margin-right: 0px;
        }
    }
}